<template>
  <main class="flex flex-col items-center justify-center h-full bg-gradient-to-b from-blue-200 to-blue-50 p-4">
    <h2 class="text-3xl sm:text-4xl md:text-5xl font-semibold text-gray-800 mb-6 text-center pb-2 border-b-4 border-gray-800">
      You are loved. Here is today’s affirmation.
    </h2>
    <p class="text-2xl sm:text-3xl md:text-4xl font-light text-gray-900 leading-snug text-center">
      {{ affirmation }}
    </p>
  </main>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getDailyAffirmation } from "../utils/getDailyAffirmation";

const affirmation = ref("");
onMounted(() => {
  affirmation.value = getDailyAffirmation();
});
</script>

<style scoped>
/* No container, no card. The large text and background gradient handle aesthetics. */
</style>

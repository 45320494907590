import affirmations from "../assets/affirmations.json";

export function getDailyAffirmation() {
  const today = new Date();
  
  // Calculate the Julian day (day of year)
  const start = new Date(today.getFullYear(), 0, 0);
  const diff = today - start;
  const oneDay = 1000 * 60 * 60 * 24;
  const julianDay = Math.floor(diff / oneDay); // 0-based day of the year
  
  // Use the julianDay to select an affirmation
  const index = julianDay % affirmations.length;
  return affirmations[index];
}

<template>
    <!-- Fully transparent background, no overlays -->
    <header class="w-full py-4 sm:py-6 text-center">
      <h1 class="text-2xl sm:text-3xl md:text-4xl font-bold tracking-wide text-gray-800">
        Your Daily Affirmation
      </h1>
    </header>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  /* No background or shadow. The gradient from App.vue shows through freely. */
  </style>
  
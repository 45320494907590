<template>
    <footer class="w-full py-3 sm:py-4 text-center">
        <p class="text-xs sm:text-sm md:text-base font-semibold text-gray-700">
          Return tomorrow for a new uplifting affirmation – a daily dose of positivity to brighten your thoughts and day.
        </p>
      <p class="text-xs sm:text-sm md:text-base text-gray-800 opacity-90 mb-1">
        &copy; 2024 Your Daily Affirmations. All rights reserved.
      </p>
    </footer>
  </template>
  
  <script setup>
  </script>
  
  <style scoped>
  /* Keeping the footer minimal and cohesive with the rest of the design */
  </style>
  